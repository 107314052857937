<template>
  <!-- <Navbar :isAccount="isAccount" /> -->
  <div class="commercial-screen">
    <div class="page-header ">
      <div class="d-flex align-items-end flex-grow-1 justify-content-end">
        <button class="btn screen-btn" @click.prevent="moveToHomeScreen">
          Insights
        </button>
      </div>
      <!-- <h2>Hello Will,</h2> -->
    </div>
    <div class="market-details">
      <div class="tab-header">
        <div class="row flex-nowrap market__row">

          <div class="col month headers">
            <h5 class="header__titles">Business Unit:</h5>
            <h6 class="details">{{ businessUnitShow.name }}</h6>
          </div>
          <div class="col market headers">
            <h5 class="header__titles">Market:</h5>
            <h6 class="details">{{ marketShow.name }}</h6>
          </div>
          <div class="col day-to-goal headers">
            <h5 class="header__titles">Days to goal:</h5>
            <h6 class="details">{{ dateGoal }} day/s</h6>
          </div>
          <div class="month headers">
            <h5 class="header__titles">Month:</h5>
            <h6 class="details">{{ monthName }}</h6>
          </div>
          <div class="col day-to-goal headers">
            <h5 class="">Date:</h5>
            <h6 class="details">{{ fullYear }}</h6>
          </div>
        </div>
      </div>

      <table class="table-container" v-if="Object.keys(clusterShow).length !== 0">
        <tr>
          <th class="table-header">Clusters</th>
          <th class="table-header">Goal</th>
          <th class="table-header">Pipeline</th>
          <th class="table-header">P.O</th>
          <th class="table-header">Invoice</th>
          <th class="table-header">Conversion</th>
          <th class="table-header">Achievement</th>
          <!-- <th class="table-header">Cluster Quantum Index</th> -->
        </tr>
        <tr v-for="(cluster, idx) in sortedClusters" :key="idx">
          <td class="table-details">
            <span>{{ cluster.name }}</span>
          </td>
          <td class="table-details">
            <span>{{ decimalNumber(cluster.goal).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.pipline === null">0</span>
            <span v-else>{{ decimalNumber(cluster.pipline).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.po === null">0</span>
            <span v-else>{{ decimalNumber(cluster.po).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.invoice === null">0</span>
            <span v-else>{{ decimalNumber(cluster.invoice).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.po_count === null || cluster.po_count === 0">0 %</span>
            <span v-else-if="cluster.pipline_count === null || cluster.pipline_count === 0">0 %</span>
            <span v-else>{{ Math.round((cluster.po / cluster.pipline) * 100) +" %"}}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.po === null">0 %</span>
            <span v-else-if="cluster.goal === 0">0 %</span>
            <span v-else>{{Math.round((cluster.po / cluster.goal) * 100) + " %" ?? 0
              }}</span>
          </td>
        </tr>

        <tr>
          <td class="table-details total__td">
            <span>Total Market Performance</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalGoals).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalPipelines).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalPos).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ decimalNumber(totalInvoices).toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{
              this.totalConversion !== "NaN" ||
              this.totalConversion !== "Infinity"
              ? decimalNumber(this.totalConversion) + " %"
              : 0 + "%"
              }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{
              totalAchievements !== "NaN"
              ? decimalNumber(totalAchievements) + " %"
              : 0 + " %"
              }}</span>
          </td>
        </tr>
      </table>

      <!-- <div v-if="Object.keys(clusterShow).length !== 0">
        <div class="data-table d-flex">
          <div class="clusters sec">
            <h5>Clusters</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span>{{
                cluster.name.slice(0, 30) +
                (cluster.name.length > 30 ? "..." : "")
              }}</span>
            </div>
            <div class="cluster-details">
              <span>Total Market Performance</span>
            </div>
          </div>
          <div class="goal sec">
            <h5>Goal</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span>{{ decimalNumber(cluster.goal) }}</span>
            </div>
            <div class="cluster-details">
              <span>{{ decimalNumber(totalGoals) }}</span>
            </div>
          </div>
          <div class="pipeline sec">
            <h5>Pipeline</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span v-if="cluster.pipline === null">0</span>
              <span v-else>{{ decimalNumber(cluster.pipline) }}</span>
            </div>
            <div class="cluster-details">
              <span>{{ decimalNumber(totalPipelines) }}</span>
            </div>
          </div>
          <div class="PO sec">
            <h5>P.O</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span v-if="cluster.po === null">0</span>
              <span v-else>{{ decimalNumber(cluster.po) }}</span>
            </div>
            <div class="cluster-details">
              <span>{{ decimalNumber(totalPos) }}</span>
            </div>
          </div>
          <div class="invoice sec">
            <h5>Invoice</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span v-if="cluster.invoice === null">0</span>
              <span v-else>{{ decimalNumber(cluster.invoice) }}</span>
            </div>
            <div class="cluster-details">
              <span>{{ decimalNumber(totalInvoices) }}</span>
            </div>
          </div>
          <div class="conversion sec">
            <h5>Conversion</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span v-if="cluster.pipline === null || cluster.pipline === 0"
                >0 %</span
              >
              <span v-else-if="cluster.po === null || cluster.po === 0"
                >0 %</span
              >
              <span v-else>{{
                Math.round((cluster.pipline / cluster.po) * 100) + " %"
              }}</span>
            </div>
            <div class="cluster-details">
              <span>{{
              this.totalConversion !== "NaN" ||
              this.totalConversion !== "Infinity"
                ? decimalNumber(this.totalConversion) + " %"
                : 0 + "%"
            }}</span>
            </div>
          </div>
          <div class="achievement sec">
            <h5>Achievement</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span v-if="cluster.po === null">0 %</span>
              <span v-else-if="cluster.goal === 0">0 %</span>
              <span v-else>{{
                (Math.round((cluster.po / cluster.goal) * 100) + " %") ?? 0 
              }}</span>
            </div>
            <div class="cluster-details">
              <span>{{
                totalAchievements !== "NaN"
                  ? decimalNumber(totalAchievements) + " %"
                  : 0 + " %"
              }}</span>
            </div>
          </div>
          <div class="envision sec">
            <h5>Envision</h5>
            <div
              class="cluster-details"
              v-for="(cluster, idx) in clusterShow"
              :key="idx"
            >
              <span>{{ decimalNumber(cluster.envision) }}</span>
            </div>
            <div class="cluster-details">
              <span>{{ decimalNumber(totalEnvisions) }}</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="no-data" v-else>
        <h3 class="text-center">No Data</h3>
      </div>
    </div>
    <footer class="text-center mt-2 footer">
      <p>
        Copyright © {{ yearBefore }}-{{ yearNow }} Caduceuslane.com. All rights
        reserved.
      </p>
    </footer>
  </div>
</template>

<style>
.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eca715;
  padding: 15%;
}

.commercial-screen {
  background-color: #fff;
  height: calc(100vh - 100px);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  padding: 0 2rem;
}

.commercial-screen .market-details {
  border-radius: 5px;
  min-height: 71vh;
  height: 81vh;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.commercial-screen .tab-content {
  border: solid 1px #eca715;
  border-radius: 5px;
  min-height: 75vh;
  padding: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.screen-btn {
  font-weight: 600!important;
  padding: 0.5rem 3rem!important;
  color: #fff !important;
  background-color: #f47d1f!important;
  cursor: pointer!important;
  border-radius: 25px!important;
}
.commercial-screen .day-to-goal,
.commercial-screen .month,
.commercial-screen .market {
  width: 20% !important;
}

.commercial-screen .headers {
  display: flex;
  flex-direction: column;
}

.commercial-screen .headers h5 {
  color: #f47d1f;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.commercial-screen .details {
  color: #3c3c3b;
  font-size: 1.2rem;
  margin-bottom: 0;
  word-break: break-all;
  width: 60%;
}

.commercial-screen .sec {
  width: 15%;
  /* background-color: #ECA715; */
  height: fit-content;
  margin-right: 5px;
}

.commercial-screen .sec h5 {
  padding: 0.5rem;
  background-color: #eca715;
  border-radius: 5px;
}

.commercial-screen .sec .cluster-details {
  padding: 0.5rem;
  background-color: #4c515c9a;
  color: #fff;
  border: solid 1px #907142;
  border-radius: 5px;
  margin-bottom: 5px;
}

.commercial-screen .clusters {
  width: 15%;
}

.commercial-screen .cluster-quantum-index {
  width: 15%;
}

.commercial-screen .page-header {
  color: #fff;
  padding: 0;
}

.footer {
  color: #747474;
}

.market__row {
  border: 1px solid #dadada;
  border-radius: 17px;
  padding: 15px;
  margin-bottom: 1rem;
}

@media (width: 1535px) {
  .commercial-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .commercial-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1440px) {
  .commercial-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .commercial-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1050px) {
  .commercial-screen .sec .cluster-details {
    font-size: 0.5rem;
  }

  .commercial-screen .sec h5 {
    font-size: 0.8rem;
  }
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import market from "../../api-services/market";
import business_unit from "../../api-services/business_unit";
import target from "../../api-services/target";
import axios from "axios";
import moment from "moment";
import store from "@/store/store";
// import store from "../../store/store";

export default {
  name: "commercial-screen",
  data() {
    return {
      isAccount: false,
      market: [],
      markets: [],
      businessUnits: [],
      marketShow: {},
      curMarketIndex: -1,
      curMarketIndexNow: 1,
      businessUnitShow: {},
      curBusinessUnitIndex: -1,
      market_id: null,
      business_unit_id: null,
      clusters: [],
      startNow: null,
      month: null,
      year: null,
      monthName: null,
      fullYear: null,
      emails: [],
      res: [],
      clusterShow: {},
      totalGoals: 0,
      totalPipelines: 0,
      totalPos: 0,
      totalInvoices: 0,
      totalConversion: 0,
      totalAchievements: 0,
      totalEnvisions: 0,
      countClusters: 0,
      dateGoal: null,
      yearNow: null,
      yearBefore: null,
    };
  },
  mounted() {
    const d = new Date();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.quarter = Math.floor((this.month - 1) / 3) + 1; // Calculate the quarter of the year
    let lastDayOfQuarter = new Date(d.getFullYear(), this.quarter * 3, 0); // Last day of the quarter

    let today = new Date(d.getFullYear(), d.getMonth(), d.getDate()); // Today's date
    this.dateGoal = Math.ceil(
      (lastDayOfQuarter - today) / (1000 * 60 * 60 * 24)
    ); // Days remaining in the quarter

    this.monthName = d.toLocaleString("default", { month: "long" });
    this.fullYear = moment(d).format("DD MMM YYYY");
    this.yearNow = d.getFullYear();
    this.yearBefore = d.getFullYear() - 1;
    this.getBusinessUnits();
    this.getMarkets();
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getMarkets() {
      try {
        const response = await market.indexWithoutLoading();
        this.markets = response.data.data;
        this.getBusinessUnits();
        store.dispatch("setIsLoading", true);
        localStorage.setItem("MarketsNumbers", response.data.data.length);
      } catch (e) {
        console.log(e);
      }
    },
    async getBusinessUnits() {
      try {
        const response = await business_unit.allWithoutLoader();
        this.businessUnits = response.data.data;
        this.showPreview();
      } catch (e) {
        console.log(e);
      }
    },

    async showPreview() {
      if (!this.markets.length)return;
      for (let bu of this.businessUnits) {
        await this.showBusinessUnits(bu);
      }

      this.showPreview();
    },
    async showBusinessUnits(businessUnit) {
      this.businessUnitShow = businessUnit;
      for (let market of this.markets) {
        this.marketShow = market;

        this.res = await target.getClustersReports(
          market.id,
          businessUnit.id
        );
        if (this.res.data.data.length > 0) {
          for (let i = 0; i < this.res.data.data.length; i++) {
            this.emails.push(this.res.data.data[i].email);
          }
        } else {
          this.clusterShow = {};
        }
        let emails = Object.values(this.emails);

        if (this.emails.length > 0) {
          store.dispatch("setIsLoading", true);
          await axios
            .post(process.env.VUE_APP_COUES_URL + "/commericalScreen", {
              email: emails,
              month: this.month,
              year: this.year,
              market: market.id,
            })
            .then((response) => {
              if (response.data) {
                store.dispatch("setIsLoading", false);
                this.hanldeDataFormat(response.data);
              }
            });

          this.emails = [];
        }
        await this.sleep(20000);
      }
    },
    moveToHomeScreen() {
      // this.$router.push({ name: 'sales-manager-account-page' });

      const url = `/sales-manager-account`
      window.open(url);
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    decimalNumber(num) {
      return parseInt(Math.round(num));
    },

    async hanldeDataFormat(data) {
      this.clusterShow = data;
      this.totalGoals = 0;
      this.totalPipelines = 0;
      this.totalPos = 0;
      this.totalInvoices = 0;
      this.totalConversion = 0;
      this.pipline_count = 0;
      this.po_count = 0;
      this.totalAchievements = 0;
      this.totalInvoices = 0;
      this.totalEnvisions = 0;
      let emailsEnvisions = [];

      if (this.res.data.data.length > 0) {
        this.countClusters = this.res.data.data.length;
        for (let j = 0; j < this.res.data.data.length; j++) {
          emailsEnvisions.push(this.res.data.data[j].email);
          this.clusterShow[this.res.data.data[j].email].name =
            this.res.data.data[j].name;
          if (this.res.data.data[j].current_target !== null) {
            this.clusterShow[this.res.data.data[j].email].goal =
              this.res.data.data[j].current_target.target;
            this.totalGoals += +this.res.data.data[j].current_target.target;
          } else {
            this.clusterShow[this.res.data.data[j].email].goal = 0;
          }
        }
      }
      for (let k = 0; k < emailsEnvisions.length; k++) {
        await axios
          .post(process.env.VUE_APP_CITIUS_URL + "/envision", {
            email: emailsEnvisions,
            month: this.month,
            year: this.year,
          })
          .then((response) => {
            if (response.data) {
              this.clusterShow[this.res.data.data[k].email].envision =
                response.data[k].envision;
            }
          });
      }

      for (let i = 0; i < Object.keys(data).length; i++) {
        this.clusterShow[Object.keys(data)[i]].email = Object.keys(data)[i];

        if (this.clusterShow[Object.keys(data)[i]].pipline !== null) {
          this.totalPipelines +=
            +this.clusterShow[Object.keys(data)[i]].pipline;
        } else {
          this.clusterShow[Object.keys(data)[i]].pipline = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].po !== null) {
          this.totalPos += +this.clusterShow[Object.keys(data)[i]].po;
        } else {
          this.clusterShow[Object.keys(data)[i]].po = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].invoice !== null) {
          this.totalInvoices += +this.clusterShow[Object.keys(data)[i]].invoice;
        } else {
          this.clusterShow[Object.keys(data)[i]].invoice = 0;
        }

        this.totalEnvisions += +this.clusterShow[Object.keys(data)[i]].envision;
      }

      let sum = 0;
      let result = 0;
      for (let key in data) {
        if (data[key].pipline_count !== 0) {
          sum += data[key].po_count / data[key].pipline_count;
        }
      }
      result = sum / Object.keys(data).length;
      if (Object.keys(data).length == 0) {
        result = 0;
      } else {
        result = sum / Object.keys(data).length;
      }
      this.totalConversion = Math.round(result * 100);

      if (this.totalGoals !== 0) {
        this.totalAchievements = (this.totalPos / this.totalGoals) * 100;
      } else {
        this.totalAchievements = 0;
      }
    },
  },
  computed: {
    sortedClusters() {
      return Object.values(this.clusterShow).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
  },
  components: {
    Navbar,
  },
};
</script>
